import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import cx from 'lib/cx';

const FormNode = forwardRef(({ className, children }, ref) => (
  <div ref={ref} className={cx('grid grid-cols-1 gap-y-2', className)}>
    {children}
  </div>
));

FormNode.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

FormNode.defaultProps = {
  className: '',
  children: null,
};

export default FormNode;
