import PropTypes from 'prop-types';

const FormLabel = ({ isRequired, children, className, ...props }) => (
  <label className={className} {...props}>
    {children} {isRequired && <span className="text-red-600">*</span>}
  </label>
);

FormLabel.propTypes = {
  isRequired: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
};

FormLabel.defaultProps = {
  isRequired: false,
  children: null,
  className: '',
};

FormLabel.defaultProps = {};

export default FormLabel;
